import { Union } from 'utils/types';

export const ButtonStyleType = Object.freeze({
  primaryFilled: 'primaryFilled',
  primarySmooth: 'primarySmooth',
  primaryOutline: 'primaryOutline',
  primaryGhost: 'primaryGhost',
  primaryLinkText: 'primaryLinkText',
  secondaryFilled: 'secondaryFilled',
  secondarySmooth: 'secondarySmooth',
  secondaryOutline: 'secondaryOutline',
  secondaryGhost: 'secondaryGhost',
  secondaryLinkText: 'secondaryLinkText',
  dangerFilled: 'dangerFilled',
  dangerSmooth: 'dangerSmooth',
  dangerOutline: 'dangerOutline',
  dangerGhost: 'dangerGhost',
  dangerLinkText: 'dangerLinkText',
  commonFilled: 'commonFilled',
  commonSmooth: 'commonSmooth',
  commonOutline: 'commonOutline',
  commonGhost: 'commonGhost',
  commonLinkText: 'commonLinkText',
  grayOutline: 'grayOutline',
  yellowFilled: 'yellowFilled',
});
export type ButtonStyleTypeType = Union<typeof ButtonStyleType>;

export const ButtonSize = Object.freeze({
  large: 'large',
  medium: 'medium',
  small: 'small',
  xSmall: 'xSmall',
});
export type ButtonSizeType = Union<typeof ButtonSize>;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  layout?: string;
  size?: ButtonSizeType;
  styleType?: ButtonStyleTypeType;
  disabled?: boolean;
  loading?: boolean;
}
