import React, { PropsWithChildren } from 'react';
import { ButtonProps, ButtonSize, ButtonStyleType } from './types';
import * as styles from './styles';

const Button = ({
  layout = '',
  children,
  size = ButtonSize.small,
  styleType = ButtonStyleType.primaryFilled,
  disabled = false,
  loading = false,
  onClick = () => null,
  className,
  ...props
}: PropsWithChildren<ButtonProps>) => (
  <button
    disabled={disabled || loading} // FIXME: loading 상태는 별도 UI로 처리하도록 수정 (진행 중 중복 클릭 방지)
    className={`${styles.button(size, styleType, disabled || loading, layout)} ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export default Button;
