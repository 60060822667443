import React, { ReactNode, PropsWithChildren } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';
import Button from '../Button';
import { ButtonProps } from '../types';

interface WithImageProps extends ButtonProps {
  leftImage?: ReactNode;
  rightImage?: ReactNode;
}

const WithImage = (withImage: PropsWithChildren<WithImageProps>) => {
  const { children, layout, leftImage, rightImage, ...restProps } = withImage;
  const layoutAddedProps = {
    layout: cx(layout, styles.imageGap),
    ...restProps,
  };
  return (
    <Button {...layoutAddedProps}>
      {leftImage}
      {children}
      {rightImage}
    </Button>
  );
};

export default WithImage;
