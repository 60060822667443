const Primary = '#0073FA';
const Secondary = '#41D293';

const Color = {
  Red: {
    10: '#FFF8F8',
    20: '#FFEBEB',
    50: '#FF7575',
    60: '#FF4E4E',
    70: '#F03838',
    80: '#E01F1F',
    90: '#D01B1B',
    100: '#B62020',
    alpha: {
      5: 'rgba(255, 78, 78, 0.05)',
      10: 'rgba(255, 78, 78, 0.1)',
      15: 'rgba(255, 78, 78, 0.15)',
      20: 'rgba(255, 78, 78, 0.2)',
      70: 'rgba(255, 78, 78, 0.7)',
    },
  },

  Green: {
    20: '#DCF9ED',
    40: '#A1EFCD',
    60: Secondary,
    80: '#08B484',
  },

  Blue: {
    10: '#F6FBFF',
    20: '#E6F3FF',
    30: '#CEE8FF',
    40: '#8ECFFF',
    50: '#4FAFFF',
    60: Primary,
    70: '#057AED',
    80: '#1068D9',
    90: '#0F55C7',
    100: '#1141A8',
    alpha: {
      5: 'rgba(0, 115, 250, 0.05)',
      10: 'rgba(0, 115, 250, 0.1)',
      15: 'rgba(0, 115, 250, 0.15)',
      20: 'rgba(0, 115, 250, 0.2)',
      70: 'rgba(0, 115, 250, 0.7)',
    },
  },

  Orange: {
    10: '#FFF7D9',
    20: '#FFEBC5',
    30: '#FFDB7D',
    40: '#FFC960',
    50: '#FFB23F',
    60: '#FF9B3F',
  },

  Gray: {
    10: '#FAFAFC',
    20: '#F5F6FA',
    30: '#E5E7EC',
    40: '#CBCFD7',
    50: '#ACB2C0',
    60: '#828896',
    70: '#5E6472',
    80: '#424958',
    90: '#303642',
    100: '#111723',
    alpha: {
      5: 'rgba(93, 100, 116, 0.05)',
      10: 'rgba(93, 100, 116, 0.1)',
      15: 'rgba(93, 100, 116, 0.15)',
      20: 'rgba(93, 100, 116, 0.2)',
      70: 'rgba(93, 100, 116, 0.7)',
    },
  },

  Alpha: {
    5: 'rgba(255, 255, 255, 0.05)',
    10: 'rgba(255, 255, 255, 0.1)',
    15: 'rgba(255, 255, 255, 0.15)',
    20: 'rgba(255, 255, 255, 0.2)',
    40: 'rgba(255, 255, 255, 0.4)',
    70: 'rgba(255, 255, 255, 0.7)',
  },

  BlackAlpha: {
    5: 'rgba(0, 0, 0, 0.05)',
    10: 'rgba(0, 0, 0, 0.1)',
    15: 'rgba(0, 0, 0, 0.15)',
    20: 'rgba(0, 0, 0, 0.2)',
    40: 'rgba(0, 0, 0, 0.4)',
    70: 'rgba(0, 0, 0, 0.7)',
  },

  Yellow: {
    60: '#FAE300',
  },

  Transparent: 'transparent',
  Inherit: 'inherit',

  White: '#FFFFFF',
  Black: '#000000',

  Primary,
  Secondary,
};

export type ColorType = keyof typeof Color;
export default Color;
