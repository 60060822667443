import Color from 'styles/colors';
import { css } from '@emotion/css';
import { ButtonSize, ButtonStyleType, ButtonStyleTypeType, ButtonSizeType } from './types';

export const button = (size: ButtonSizeType, styleType: ButtonStyleTypeType, disabled: boolean, layout: string) => css`
  ${getButton()}
  ${getSize(size)}
  ${getStyleType(styleType)}
  ${layout}
  ${getDisabled(disabled)}
`;

const getButton = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 700;
  &:focus {
    outline: none;
  }
`;

const getDisabled = (disabled: boolean) => {
  if (!disabled) {
    return css`
      cursor: pointer;
    `;
  }
  return css`
    cursor: not-allowed;
    color: ${Color.Gray['50']} !important;
    background-color: ${Color.Gray['30']};
    &:hover {
      background-color: ${Color.Gray['30']};
    }
    &:active {
      background-color: ${Color.Gray['30']};
    }
  `;
};

const getSize = (size: ButtonSizeType) => {
  switch (size) {
    case ButtonSize.large:
      return css`
        height: 56px;
        padding: 0px 14px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        min-width: 96px;
      `;
    case ButtonSize.medium:
      return css`
        height: 48px;
        padding: 0px 12px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        min-width: 88px;
      `;
    case ButtonSize.small:
      return css`
        height: 40px;
        padding: 0px 9.5px;
        border-radius: 6px;
        font-size: 14px;
        line-height: 21px;
        min-width: 80px;
      `;
    case ButtonSize.xSmall:
      return css`
        height: 36px;
        padding: 0px 8px;
        border-radius: 4px;
        font-size: 13px;
        line-height: 19.5px;
        min-width: 72px;
      `;
    default:
  }
};

// TODO: 최신 StyleType 적용하기
const getStyleType = (type: ButtonStyleTypeType) => {
  switch (type) {
    case ButtonStyleType.primaryFilled:
      return css`
        background-color: ${Color.Blue['60']};
        color: ${Color.White};
        &:hover {
          background-color: ${Color.Blue['70']};
        }
        &:active {
          background-color: ${Color.Blue['80']};
        }
      `;

    case ButtonStyleType.primarySmooth:
      return css`
        background-color: ${Color.Blue.alpha['10']};
        color: ${Color.Blue['60']};
        &:hover {
          background-color: ${Color.Blue.alpha['15']};
        }
        &:active {
          background-color: ${Color.Blue.alpha['20']};
        }
      `;

    case ButtonStyleType.primaryOutline:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Blue['60']};
        border: 1px solid ${Color.Blue['60']};
        &:hover {
          background-color: ${Color.Blue.alpha['5']};
        }
        &:active {
          background-color: ${Color.Blue.alpha['10']};
        }
      `;

    case ButtonStyleType.primaryGhost:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Blue['60']};
        &:hover {
          background-color: ${Color.Blue.alpha['5']};
        }
        &:active {
          background-color: ${Color.Blue.alpha['10']};
        }
      `;
    case ButtonStyleType.primaryLinkText:
      return css`
        color: ${Color.Blue['60']};
        &:hover {
          color: ${Color.Blue['70']};
        }
        &:active {
          color: ${Color.Blue['80']};
        }
      `;

    case ButtonStyleType.secondaryFilled:
      return css`
        background-color: ${Color.Gray['70']};
        color: ${Color.White};
        &:hover {
          background-color: ${Color.Gray['80']};
        }
        &:active {
          background-color: ${Color.Gray['90']};
        }
      `;

    case ButtonStyleType.secondarySmooth:
      return css`
        background-color: ${Color.Gray.alpha['10']};
        color: ${Color.Gray['70']};
        &:hover {
          background-color: ${Color.Gray.alpha['15']};
        }
        &:active {
          background-color: ${Color.Gray.alpha['20']};
        }
      `;

    case ButtonStyleType.secondaryOutline:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Gray['70']};
        border: 1px solid ${Color.Gray.alpha['20']};
        &:hover {
          background-color: ${Color.Gray.alpha['5']};
        }
        &:active {
          background-color: ${Color.Gray.alpha['10']};
        }
      `;

    case ButtonStyleType.secondaryGhost:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Gray['70']};
        &:hover {
          background-color: ${Color.Gray.alpha['5']};
        }
        &:active {
          background-color: ${Color.Gray.alpha['10']};
        }
      `;
    case ButtonStyleType.secondaryLinkText:
      return css`
        color: ${Color.Gray[60]};
        &:hover {
          color: ${Color.Gray['70']};
        }
        &:active {
          color: ${Color.Gray['80']};
        }
      `;
    case ButtonStyleType.dangerFilled:
      return css`
        background-color: ${Color.Red['60']};
        color: ${Color.White};
        &:hover {
          background-color: ${Color.Red['70']};
        }
        &:active {
          background-color: ${Color.Red['80']};
        }
      `;

    case ButtonStyleType.dangerSmooth:
      return css`
        background-color: ${Color.Red.alpha['10']};
        color: ${Color.Red['60']};
        &:hover {
          background-color: ${Color.Red.alpha['15']};
        }
        &:active {
          background-color: ${Color.Red.alpha['20']};
        }
      `;

    case ButtonStyleType.dangerOutline:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Red['60']};
        border: 1px solid ${Color.Red.alpha['20']};
        &:hover {
          background-color: ${Color.Red.alpha['5']};
        }
        &:active {
          background-color: ${Color.Red.alpha['10']};
        }
      `;

    case ButtonStyleType.dangerGhost:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Red['60']};
        &:hover {
          background-color: ${Color.Red.alpha['5']};
        }
        &:active {
          background-color: ${Color.Red.alpha['10']};
        }
      `;
    case ButtonStyleType.dangerLinkText:
      return css`
        color: ${Color.Red['60']};
        &:hover {
          color: ${Color.Red['70']};
        }
        &:active {
          color: ${Color.Red['80']};
        }
      `;
    case ButtonStyleType.commonFilled:
      return css`
        background-color: ${Color.Gray.alpha['5']};
        color: ${Color.Gray[60]};
      `;

    case ButtonStyleType.commonSmooth:
      return css`
        background-color: ${Color.Gray.alpha['5']};
        color: ${Color.Gray[60]};
      `;

    case ButtonStyleType.commonOutline:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Gray[60]};
        border: 1px solid ${Color.Gray['30']};
      `;

    case ButtonStyleType.commonGhost:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Gray[80]};
        &:active {
          background-color: ${Color.Gray['20']};
          color: ${Color.Gray['100']};
        }
      `;

    case ButtonStyleType.commonLinkText:
      return css`
        color: ${Color.Gray[60]};
      `;

    case ButtonStyleType.grayOutline:
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Gray[100]};
        border: 1px solid ${Color.Gray['40']};
      `;

    case ButtonStyleType.yellowFilled:
      return css`
        background-color: ${Color.Yellow[60]};
        color: ${Color.Black};
        &:active {
          background-color: ${Color.Yellow[60]};
          color: ${Color.Black};
        }
      `;
    default:
  }
};
